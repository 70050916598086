export const es= {
    action:{
        accept:"Acepta",
        postponi:"Pospón",
        declina:"Rechaza",
        cancel:"Cancela",
        download:"Descarga",
        app:"Abre la App",
        salta:"Salta",
        close:"Cierra",
        riprova:"Intentar otra vez"
    },
    onboard:{
        lang:{
            title:"Selecciona",
            text:"¿En qué idioma prefieres escuchar los contenidos?"
        },
        accessibility:{
            title:"Accesibilidad",
            text:"Activar para mostrar subtítulos de contenido multimedia"
        },
        percorsi:{
            title:"Select ",
            text:"wich path do you want to follow"
        },
        tag:{
            title:"Acerca el smartphone a los SilenceTags",
            text:"Utiliza la APP para acceder a los contenidos"
        },
        qr:{
            alternative:"Si no",
            title:"Escanea",
            text:"Encuadra los códigos QR que encontrarás durante el recorrido"
        },


    },
    networkerror:{
		title:"Error Network",
        text:"No es posible descargar los contenidos en modalidad offline!",
        action:"Intenta más tarde"
    },
    storageError:{
		title:"Espacio insuficiente",
        text:"No es posible descargar los contenidos. Utiliza la modalidad online para hacerlo!",
        action:"Ok"
    },
    update:{
		title:"Actualizaciones",
        text:"Hay nuevas actualizaciones disponibles! ¿Deseas descargarlas ahora?"
    },
    scarica:{
		title:"Desacarga los contenidos",							 
        text:"La APP estará disponible pronto. Puede requerir algunos minutos",
        errorconn:{
            title:"Error de descarga",
            text:"Se encontró un problema de descarga, verifique su conexión e intente nuevamente"
        },
        alertannulla:{
            title:"Atención",
            text:"Los contenidos no han sido descargados, la aplicación solo puede funcionar en línea. </br> Vuelva a intentarlo más tarde para descargar para una mejor experiencia"
        }
    },
    main:{

        scan:"Escanéa el código",
        start:"Escanéa el SilenceTag",
        stop:"Para el SilenceTag",
        alertApp:{
            title:"Función no disponible",
            text:"Esta función està disponible solo en la APP",
        },
		timeoutTagTitle:"ALERTA",
		timeoutTagText:"SilenceTag NO detectado, quizá esté apagado. Utiliza el,\"Escanea el código QR\" botón",
		timeoutTagAccept:"Continúa",
       
    
    }, capting:[
        "Te busco", 
        "Mantén el teléfono quieto",
        "Un momento más"
    ],

    menu:{
        title:"Configuración",
        content:{
            title:"Actualiza los contenidos"
        },
        accessibility:{
            title:"Accessibilità",
            subtitle:"Activar para mostrar subtítulos de contenido multimedia",
            supportoVisuale:"Subtítulos/Lengua de Signos"
        },
        percorsi:{
            title:"Itinerarios",
            subtitle:"Itinerarios descargados",
            add:"Cambiar itinerario",
            change:"Cambiar itinerario",
            add:"Añadir un nuevo itinerario",
            alert:"Atención: esta operación descargará el contenido del nuevo itinerario",
            select:"Itinerarios activos",
        },
        lang:{
            title:"Idioma",
            subtitle:"Descarga idiomas",
            add:"Agrega un nuevo idioma",
            select:"Contenido disponible",
            alert:"Alerta, con esta operacion descargarás los contenidos en el idioma seleccionado",
            it:"Italiano",
            en:"Inglés",
            fr:"Francés",
            de:"Alemán",
            es:"Español"
        },
        help:"Ayuda",
        copyright:"Copyright y Privacy",
        privacy:"Privacy",
        termini:"Términos de servicio",
        test:{
            title:"Test",
            inizioTest:"Iniciar TEST",
            stopTest:"Dejar TEST",
            testoIstruzioni:"Desconecte sus auriculares, suba el volumen y ejecute la prueba",
            compatibileTest:"El dispositivo es compatible",
            nonCompatibileTest:"El dispositivo no es compatible",
        }
        
    },
    raccolta:{
        title:"Recolección de datos",
        subtitle:"Revive nuevamente la experiencia",
        alerttext:"Aún no hay contenidos visualizados. \n Al final del recorrido encontrarás los contenidos en esta sección"
    },
    schede:{
        back:"Back"
    }
   
}